
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import EditControls from "@/components/EditControls.vue";
import ReadableTimestamp from "@/components/ReadableTimestamp.vue";
import { Worker } from "@/store/workers/types";

const WorkerRow = Vue.extend({
  props: {
    locked: Boolean,
    worker: Worker,
  },

  data() {
    return {
      nickname: this.worker.nickname,
      configname: this.worker.configname,
      editing: false,
    };
  },

  components: {
    EditControls,
    ReadableTimestamp,
  },

  watch: {
    configname() {
      this.configs.forEach((config) => {
        if (this.configname === config.nickname) {
          this.config = config.config;
        }
      });
    },
  },

  computed: {
    ...mapGetters("configs", ["configs"]),
  },

  methods: {
    editable() {
      this.editing = true;
    },

    save() {
      this.edit({
        ...this.worker,
        nickname: this.nickname,
        configname: this.configname,
      });
      this.editing = false;
    },

    destroy() {
      this.remove(this.worker);
    },

    cancel() {
      this.nickname = this.worker.nickname;
      this.configname = this.worker.configname;
      this.editing = false;
    },

    ...mapActions("workers", ["edit", "remove"]),
  },
});

export default WorkerRow;
