
import Vue from "vue";
import { mapGetters } from "vuex";
import { Profile } from "@/store/profiles/types";
import { celsius2fahrenheit, celsius2kelvin } from "../../shared/utils";

const ProfileNarrow = Vue.extend({
  props: {
    profile: Profile,
  },

  data() {
    return {
      day: true,
      ts: new Date(),
    };
  },

  computed: {
    duration(): number {
      const hours = this.profile.lampduration["hours"];
      return this.day ? hours : 24 - hours;
    },

    humidity(): number {
      return this.profile.targetHumidity(this.ts);
    },

    icon(): string {
      if (this.day) {
        return "caret-up";
      } else {
        return "caret-down";
      }
    },

    style(): string {
      if (this.day) {
        return "color: #ffe08a";
      } else {
        return "color: #7a7a7a";
      }
    },

    temperature(): number {
      const temp = this.day
        ? this.profile.lampontemperature
        : this.profile.lampofftemperature;
      if (this.settings.units === "F") {
        return celsius2fahrenheit(temp);
      } else if (this.settings.units === "K") {
        return celsius2kelvin(temp);
      }
      return temp;
    },

    ...mapGetters("settings", ["settings"]),
  },

  methods: {
    toggle() {
      this.day = !this.day;
    },
  },
});

export default ProfileNarrow;
