
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import EditControls from "@/components/EditControls.vue";
import MeterActual from "@/components/MeterActual.vue";
import ReadableTimestamp from "@/components/ReadableTimestamp.vue";
import { Meter } from "@/store/meters/types";

const MeterRow = Vue.extend({
  props: {
    meter: Meter,
    locked: Boolean,
    units: String,
  },

  data() {
    return {
      nickname: this.meter.nickname,
      updatedat: new Date(Date.parse(this.meter.updatedat)),
      updating: false,
      editing: false,
    };
  },

  components: {
    EditControls,
    MeterActual,
    ReadableTimestamp,
  },

  watch: {
    meter() {
      this.updating = false;
    },
  },

  computed: {
    zone() {
      const found = this.zones.filter((zone) => {
        const meters = zone.meters.filter((meter) => {
          return this.meter.device === meter.device;
        });
        return meters.length !== 0;
      });

      return found.length !== 0 ? found[0] : null;
    },

    zoneid() {
      const zone = this.zone;
      if (zone) {
        return zone.id;
      }
      return 0;
    },

    zonename() {
      const zone = this.zone;
      if (zone) {
        return zone.nickname;
      }
      return "";
    },

    ...mapGetters("zones", ["zones"]),
  },

  methods: {
    editable(): void {
      this.editing = true;
    },

    save(): void {
      this.edit({
        ...this.meter,
        nickname: this.nickname,
      });
      this.editing = false;
    },

    cancel() {
      this.nickname = this.meter.nickname;
      this.editing = false;
    },

    destroy() {
      if (confirm("OK to remove?")) {
        this.remove(this.meter);
      }
    },

    ...mapActions("meters", ["edit", "remove"]),
  },
});

export default MeterRow;
