
import Vue from "vue";
import { mapGetters } from "vuex";

import DeviceTile from "@/components/DeviceTile.vue";
import MeterTile from "@/components/MeterTile.vue";
import ProfileTile from "@/components/ProfileTile.vue";
import ZoneDetail from "@/components/ZoneDetail.vue";
import ZoneTile from "@/components/ZoneTile.vue";
import WorkerTile from "@/components/WorkerTile.vue";
import ConfigTile from "@/components/ConfigTile.vue";

const SingleColumn = Vue.extend({
  props: {
    items: [],
    locked: Boolean,
    type: String,
  },

  components: {
    DeviceTile,
    MeterTile,
    ProfileTile,
    ZoneDetail,
    ZoneTile,
    WorkerTile,
    ConfigTile,
  },

  computed: {
    ...mapGetters("settings", ["settings"]),
  },
});

export default SingleColumn;
