
import Vue from "vue";
import { mapGetters } from "vuex";

import ConfigRow from "@/components/ConfigRow.vue";
import DeviceRow from "@/components/DeviceRow.vue";
import MeterRow from "@/components/MeterRow.vue";
import ProfileRow from "@/components/ProfileRow.vue";
import WorkerRow from "@/components/WorkerRow.vue";
import ZoneRow from "@/components/ZoneRow.vue";

const FullTable = Vue.extend({
  props: {
    headings: [],
    items: [],
    locked: Boolean,
    type: String,
  },

  components: {
    ConfigRow,
    DeviceRow,
    MeterRow,
    ProfileRow,
    WorkerRow,
    ZoneRow,
  },

  computed: {
    ...mapGetters("settings", ["settings"]),
  },
});

export default FullTable;
