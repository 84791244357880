
import Vue from "vue";

import EditControls from "@/components/EditControls.vue";
import ReadableTimestamp from "@/components/ReadableTimestamp.vue";
import { Config } from "@/store/configs/types";
import { mapActions } from "vuex";

const ConfigTile = Vue.extend({
  props: {
    config: Config,
  },

  data() {
    return {
      nickname: this.config.nickname,
      configStr: this.config.toString(),
      readable: true,
      editing: false,
    };
  },

  components: {
    EditControls,
    ReadableTimestamp,
  },

  computed: {
    lastupdate() {
      return new Date(Date.parse(this.config.updatedat));
    },
  },

  methods: {
    editable() {
      this.editing = true;
    },

    save() {
      this.edit({
        ...this.config,
        nickname: this.nickname,
        config: this.configStr,
        currentName: this.config.nickname,
      });
      this.editing = false;
    },

    cancel() {
      this.nickname = this.config.nickname;
      this.configStr = this.config.toString();
      this.editing = false;
    },

    ...mapActions("configs", ["edit"]),
  },
});

export default ConfigTile;
