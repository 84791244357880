
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

import ZoneDetail from "@/components/ZoneDetail.vue";
import EditControls from "@/components/EditControls.vue";

const ZonePage = Vue.extend({
  components: {
    EditControls,
    ZoneDetail,
  },

  data() {
    return {
      nickname: "",
      profileid: 0,
      lamponleafdiff: 0,
      lampoffleafdiff: 0,
      maxirrigators: 0,
      zonemeters: [],
      zonedevices: [],
      zonechildren: [],
      editing: false,
    };
  },

  mounted() {
    this.nickname = this.zone.nickname;
    this.profileid = this.zone.profile.id;

    if (this.settings.units === "F") {
      this.lamponleafdiff = (this.zone.lamponleafdiff * 9) / 5;
      this.lampoffleafdiff = (this.zone.lampoffleafdiff * 9) / 5;
    } else {
      this.lamponleafdiff = this.zone.lamponleafdiff;
      this.lampoffleafdiff = this.zone.lampoffleafdiff;
    }

    this.maxirrigators = this.zone.maxirrigators;

    this.zone.meters.forEach((m) => {
      this.zonemeters.push(m.device);
    });

    this.zone.devices.forEach((d) => {
      this.zonedevices.push(d.device);
    });

    this.zone.children.forEach((c) => {
      this.zonechildren.push(c);
    });
  },

  computed: {
    locked() {
      return this.$route.params.locked;
    },

    zone() {
      const id = this.$route.params.id;
      const z = this.zones.filter((z) => {
        return z.id === id;
      });
      return z[0];
    },

    ...mapGetters("settings", ["settings"]),
    ...mapGetters("profiles", ["profiles"]),
    ...mapGetters("zones", ["zones"]),
    ...mapGetters("devices", ["devices"]),
    ...mapGetters("meters", ["meters"]),
  },

  methods: {
    editable() {
      this.editing = true;
    },

    save() {
      let lampon = this.lamponleafdiff;
      let lampoff = this.lampoffleafdiff;
      if (this.units === "F") {
        lampon = (lampon * 5) / 9;
        lampoff = (lampoff * 5) / 9;
      }

      const zone = {
        ...this.zone,
        nickname: this.nickname,
        profileid: this.profileid,
        lamponleafdiff: lampon,
        lampoffleafdiff: lampoff,
        maxirrigators: this.maxirrigators,
        meters: this.zonemeters,
        devices: this.zonedevices,
        children: this.zonechildren,
      };
      this.edit(zone);
      this.editing = false;
    },

    destroy() {
      if (confirm("OK to delete?")) {
        this.remove(this.zone);
        this.$router.push({ name: "dashboard" });
      }
    },

    cancel() {
      this.editing = false;
    },

    ...mapActions("zones", ["edit", "remove"]),
  },
});

export default ZonePage;
