
import Vue from "vue";
import { Worker } from "@/store/profiles/types";
import ReadableTimestamp from "@/components/ReadableTimestamp.vue";

const WorkerNarrow = Vue.extend({
  props: {
    worker: Worker,
  },

  components: {
    ReadableTimestamp,
  },
});

export default WorkerNarrow;
