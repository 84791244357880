
import Vue from "vue";
import { mapGetters } from "vuex";
import DeviceChart from "@/components/DeviceChart.vue";
import { convertToLocalTime } from "date-fns-timezone";
import BackToDashboard from "@/components/BackToDashboard.vue";

const Statuses = Vue.extend({
  props: {
    name: { type: String, default: "" },
  },

  components: {
    BackToDashboard,
    DeviceChart,
  },

  data() {
    return {
      range: "hour",
      statuses: [] as { x: Date; y: number }[],
    };
  },

  computed: {
    ...mapGetters("settings", ["settings"]),
  },

  mounted() {
    this.refresh();
  },

  watch: {
    range() {
      this.refresh();
    },
  },

  methods: {
    refresh() {
      const xhr = new XMLHttpRequest();
      const url = process.env.VUE_APP_API_URL;

      xhr.open(
        "GET",
        `${url}/statuses/?device=${this.$route.params.device}&last=${this.range}`
      );

      xhr.onload = () => {
        const data = JSON.parse(xhr.response);
        if (!data.error) {
          this.statuses = [];
          data.forEach((d: { observedat: Date; status: string }) => {
            const ts = convertToLocalTime(d.observedat, {
              timeZone: this.settings.timezone,
            });
            const status = {
              x: ts,
              y: d.status === "on" ? 1 : 0,
            };

            this.statuses.push(status);
          });
        }
      };

      xhr.send();
    },
  },
});
export default Statuses;
