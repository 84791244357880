
import Vue from "vue";

const Button = Vue.extend({
  props: {
    show: { type: Boolean, default: true },
    callback: Function,
    label: String,
    icon: { type: String, default: "cannabis" },
    size: { type: String, default: "small" },
    color: { type: String, default: "primary" },
  },

  computed: {
    buttonClass() {
      return `button is-${this.size} has-text-${this.color}`;
    },
  },
});
export default Button;
