
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

import HerbertCollection from "@/components/HerbertCollection.vue";
import CurrentConditions from "@/components/CurrentConditions.vue";
import HerbertNavbar from "@/components/HerbertNavbar.vue";
import ReadableTimestamp from "@/components/ReadableTimestamp.vue";
import SettingsPage from "@/components/SettingsPage.vue";
import FullTimestamp from "@/components/FullTimestamp.vue";

const Dashboard = Vue.extend({
  data() {
    return {
      filter: "",
      picked: "overview",
      locked: true,
      ts: new Date(),
    };
  },

  components: {
    CurrentConditions,
    FullTimestamp,
    HerbertCollection,
    HerbertNavbar,
    ReadableTimestamp,
    SettingsPage,
  },

  computed: {
    cameras() {
      return this.workers.filter((worker) => {
        return worker.camera !== null;
      });
    },

    logo() {
      if (this.settings.logo) {
        return this.settings.logo;
      }
      return null;
    },

    ...mapGetters("settings", ["settings"]),
    ...mapGetters("workers", ["workers"]),
    ...mapGetters("zones", ["zones"]),
  },

  mounted() {
    const locked = this.$cookies.get("locked");
    console.log("cookie locked", locked);
    if (locked) {
      this.locked = locked === "true";
    }
    console.log("this locked", this.locked);

    this.refresh();
  },

  methods: {
    is(section: string) {
      return this.picked === section;
    },

    refresh() {
      this["devices/fetchData"]();
      this["meters/fetchData"]();
      this["profiles/fetchData"]();
      this["workers/fetchData"]();
      this["configs/fetchData"]();
      this["zones/fetchData"]();
      this["settings/fetchData"]();

      this.ts = new Date();

      setTimeout(this.refresh, this.settings.refresh);
    },

    saveSettings(settings: string) {
      this["settings/edit"](JSON.parse(settings));
    },

    selected(val: string) {
      this.picked = val;
    },

    setFilter(val: string) {
      this.filter = val;
    },

    toggle() {
      console.log("lock", this.locked, this.settings.pin);
      if (!this.locked) {
        this.selected("overview");
        this.locked = true;
      } else if (this.settings.pin === "") {
        alert("Use Settings > PIN to set an access code.");
        this.locked = false;
      } else {
        const pin = prompt("Enter PIN to unlock.");
        if (pin === this.settings.pin) {
          this.locked = false;
        }
      }
      this.$cookies.set("locked", this.locked);
    },

    ...mapActions([
      "devices/fetchData",
      "meters/fetchData",
      "profiles/fetchData",
      "workers/fetchData",
      "configs/fetchData",
      "zones/fetchData",
      "settings/fetchData",
      "settings/edit",
    ]),
  },
});

export default Dashboard;
