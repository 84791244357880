
import Vue from "vue";
import HerbertButton from "@/components/HerbertButton.vue";

const AddControls = Vue.extend({
  data() {
    return {
      adding: false,
    };
  },

  components: {
    HerbertButton,
  },

  methods: {
    on() {
      this.$emit("on-add");
      this.adding = true;
    },

    save() {
      this.$emit("on-save");
      this.adding = false;
    },

    cancel() {
      this.$emit("on-cancel");
      this.adding = false;
    },
  },
});
export default AddControls;
