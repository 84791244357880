
import Vue from "vue";

import EditControls from "@/components/EditControls.vue";
import ReadableTimestamp from "@/components/ReadableTimestamp.vue";
import { Worker } from "@/store/workers/types";
import { mapActions, mapGetters } from "vuex";

const WorkerTile = Vue.extend({
  props: {
    locked: Boolean,
    worker: Worker,
  },

  data() {
    return {
      nickname: this.worker.nickname || this.worker.worker,
      configname: this.worker.configname,
      config: JSON.stringify(this.worker.config),
      readable: true,
      editing: false,
    };
  },

  components: {
    EditControls,
    ReadableTimestamp,
  },

  watch: {
    configname() {
      this.configs.forEach((config) => {
        if (this.configname === config.nickname) {
          this.config = config.config;
        }
      });
    },
  },

  computed: {
    camera() {
      if (this.worker.camera) {
        return this.worker.camera;
      }
      return null;
    },

    ...mapGetters("configs", ["configs"]),
  },

  methods: {
    editable() {
      this.editing = true;
    },

    save() {
      this.edit({
        ...this.worker,
        nickname: this.nickname,
        configname: this.configname,
      });
      this.editing = false;
    },

    destroy() {
      if (confirm("OK to remove?")) {
        this.remove(this.worker);
      }
    },

    cancel() {
      this.nickname = this.worker.nickname;
      this.configname = this.worker.configname;
      this.editing = false;
    },

    ...mapActions("workers", ["edit", "remove"]),
  },
});

export default WorkerTile;
