
import Vue from "vue";
import { Zone } from "@/store/zones/types";
import DeviceChart from "@/components/DeviceChart.vue";
import HumidityChart from "@/components/HumidityChart.vue";
import TemperatureChart from "@/components/TemperatureChart.vue";

const ZoneChart = Vue.extend({
  props: {
    zone: Zone,
    units: String,
  },

  components: {
    DeviceChart,
    HumidityChart,
    TemperatureChart,
  },
});

export default ZoneChart;
