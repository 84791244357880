
import Vue from "vue";

import ConfigNarrow from "@/components/ConfigNarrow.vue";
import DeviceNarrow from "@/components/DeviceNarrow.vue";
import MeterNarrow from "@/components/MeterNarrow.vue";
import ProfileNarrow from "@/components/ProfileNarrow.vue";
import WorkerNarrow from "@/components/WorkerNarrow.vue";
import ZoneNarrow from "@/components/ZoneNarrow.vue";

const NarrowTable = Vue.extend({
  props: {
    items: [],
    type: String,
    locked: Boolean,
  },

  components: {
    ConfigNarrow,
    DeviceNarrow,
    MeterNarrow,
    ProfileNarrow,
    WorkerNarrow,
    ZoneNarrow,
  },
});

export default NarrowTable;
