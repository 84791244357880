
import Vue from "vue";
import { mapGetters } from "vuex";

const SelectZoneForDevice = Vue.extend({
  props: {
    zoneid: Number,
  },

  data() {
    return {
      selected: this.zoneid,
    };
  },

  computed: {
    ...mapGetters("zones", ["zones"]),
  },

  watch: {
    selected(val: string) {
      this.$emit("select-zone", val);
    },
  },
});

export default SelectZoneForDevice;
