
import Vue from "vue";

const SelectControlType = Vue.extend({
  props: {
    controltype: String,
  },

  data() {
    return {
      controltypes: ["HI_LO", "TARGETS", "VPD"],
      selected: this.controltype,
    };
  },

  watch: {
    selected(val: string) {
      this.$emit("select-controltype", val);
    },
  },
});

export default SelectControlType;
