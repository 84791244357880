
import Vue from "vue";
import { Config } from "@/store/configs/types";
import ReadableTimestamp from "@/components/ReadableTimestamp.vue";

const ConfigNarrow = Vue.extend({
  props: {
    config: Config,
  },

  components: {
    ReadableTimestamp,
  },
});

export default ConfigNarrow;
