
import Vue from "vue";
import { mapGetters } from "vuex";

const HerbertNavbar = Vue.extend({
  props: {
    locked: { type: Boolean, default: true },
  },

  data() {
    return {
      filter: "",
    };
  },

  computed: {
    lockIcon() {
      return this.locked ? "lock" : "lock-open";
    },

    logo() {
      if (this.settings.logo) {
        return this.settings.logo;
      }
      return null;
    },

    title() {
      return this.settings.title || "Grow More";
    },

    ...mapGetters("settings", ["settings"]),
  },

  watch: {
    filter(val: string) {
      this.$emit("search-on", val);
    },
  },

  methods: {
    burger() {
      this.$refs.navbarBurger.classList.toggle("is-active");
      this.$refs.navMenuIndex.classList.toggle("is-active");
    },

    is(section: string) {
      if (this.picked === section) {
        return "is-active";
      }
      return "";
    },

    pick(section: string) {
      this.picked = section;
      this.filter = "";
      this.$emit("selected", section);
      if (this.picked !== "overview") {
        this.$refs.navbarBurger.classList.toggle("is-active");
        this.$refs.navMenuIndex.classList.toggle("is-active");
      }
    },

    search() {
      this.$refs.search.classList.toggle("herbert-is-visible");

      if (this.$refs.search.classList.contains("herbert-is-visible")) {
        this.$refs.herbertSearch.focus();
      }
    },

    toggle() {
      this.$emit("toggle");
    },
  },
});

export default HerbertNavbar;
